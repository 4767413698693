import { Environment } from './interface';

export const environment: Environment = {
  production: true,
  apiUrl: 'https://beta1.dev.topseller.ru/api/v1/',
  apiKey: '',
  wbAdsUrl: 'http://wbads.dev.topseller.ru',
  hubUrl: 'https://beta1.dev.topseller.ru',
  dropshipUrl: 'https://ds.dev.topseller.ru/api',
  aiUrl: 'http://ai-tools.dev.topseller.ru',
  connectUrl: 'http://connect.topseller.ru',
  pimUrl: 'http://content.topseller.ru/hub',
  journalUrl: 'https://topseller.vercel.app',
  rpUrl: 'http://repricer.dev.topseller.ru',
  eaUrl: 'https://externalytics.topseller.ru/v1',
};
